<template>
  <div class="container">
    <div class="row py-3">
      <div class="col-12">
        <router-link class="mr-3" :to="getLink('')">
          <small>INICIO</small>
        </router-link>
        <router-link class="mr-3" :to="getLink('productos')">
          <small>PRODUCTOS</small>
        </router-link>
        <small class="text-uppercase">{{ product.name }}</small>
      </div>
    </div>
    <div class="row no">
      <div class="col-12 bg-white py-4 px-1">
        <product :id="$route.params.id" notes="false" size="lg" />
      </div>
      <div class="w-100 py-4"></div>
      <div class="col-12">
        <h4>PRODUCTOS RELACIONADOS</h4>
        <div class="row" v-if="loadingRelated">
          <div class="col-3 mb-4" v-for="i in 4" :key="i">
            <div class="px-2">
              <b-skeleton-img />
              <div class="w-100 mt-2"></div>
              <b-skeleton width="50%" />
            </div>
          </div>
        </div>
        <div class="row mb-4" v-else style="overflow-x: scroll">
          <div
            class="col-6 col-md-3 mb-4 w-25"
            v-for="product in products"
            :key="product._id"
          >
            <router-link :to="getLink(`productos/${product._id}`)">
              <div class="px-2">
                <b-img-lazy
                  class="img-fluid w-100"
                  :src="getImage(product.image)"
                ></b-img-lazy>

                <div class="position-relative">
                  <div
                    class="badge badge-danger rounded-0 position-absolute"
                    style="top: -20px; right: 0px"
                  >
                    <span style="font-size: 12px">
                      ${{ formatNumber(product.value) }}
                    </span>
                  </div>
                  <div class="overflow-hidden pt-2">
                    <span
                      class="
                        card-title
                        font-weight-bold
                        text-uppercase text-nowrap
                        pr-2
                      "
                    >
                      {{ product.name }}
                    </span>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Product from "./Product";
export default {
  components: {
    Product,
  },
  data() {
    return {
      related: [],
      loadingRelated: true,
      products: [],
    };
  },
  mounted() {
    this.loadProduct();
    window.scroll(0, 0);
  },
  computed: {
    product() {
      return this.$store.state.product;
    }
  },
  methods: {
    goProducts() {
      this.$router.push(this.getLink("productos"));
    },
    loadProduct() {
      this.loadingRelated = true;
      let { _id } = this.$store.state.info;
      let pid = this.$route.params.id;
      this.$http
        .get(`${_id}/related/${pid}`)
        .then((res) => {
          this.products = res.data.data;
        })
        .finally(() => {
          this.loadingRelated = false;
        });
    },
  },
  watch: {
    $route: function () {
      window.scroll(0, 0)
      this.loadProduct();
    },
  }
};
</script>